<template>
  <div>
    <div class="flex justify-center mb-2">
      <div class="inline-flex relative">
        <croppa
          v-model="myCroppa"
          :width="350"
          :height="477"
          :quality="1"
          placeholder=""
          canvas-color="#FFFFFF"
          disable-click-to-choose
          :show-remove-button="false"
          :initial-image="image"
          class="border bg-white"
        />
        <button
          class="absolute top-0 right-0 z-10 h-12 w-12 flex items-center justify-center bg-white
          mt-4 mr-4 rounded-full focus:outline-none focus:shadow-outline tap-color-none border"
          @click="rotate"
        >
          <Icon name="crop-rotate" />
        </button>
      </div>
    </div>
    <div class="text-gray-600 text-xs text-center">{{ $t('moveOrZoomPhoto') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      myCroppa: {},
    };
  },

  methods: {
    generate() {
      const dataUrl = this.myCroppa.generateDataUrl('image/png', 0.6);
      this.$emit('generated', dataUrl);
      return dataUrl;
    },
    rotate() {
      this.myCroppa.rotate(2);
    },
  },
};
</script>
