<template>
  <div class="bg-white">
    <div v-if="step === 1">
      <div class="max-w-lg mx-auto pt-32 flex">
        <Button class="w-full mr-2" @click="step = 2">
          {{ $t('capturePhoto') }}
        </Button>
        <Button class="w-full ml-2" @click="openFileUpload">
          {{ $t('uploadFile') }}
        </Button>
        <input
          type="file"
          id="file"
          class="hidden"
          accept="image/png, image/jpeg"
          @change="onFileUploadChange"
        />
      </div>
    </div>
    <div v-else-if="step === 2">
      <Camera @capture="onCapture" @error="onCameraError" @back="step = 1"/>
    </div>
    <div v-else-if="step === 3" class="max-w-lg mx-auto pt-32">
      <ImageCrop ref="crop" :image="img"/>
      <div class="flex mt-6">
        <Button
          class="mr-2 w-full"
          @click="img = null; step = 1;"
        >
          {{ $t('cancel') }}
        </Button>
        <Button
          class="ml-2 w-full"
          primary
          :disabled="loading"
          @click="onSave"
        >
          {{ $t('save') }}
        </Button>
      </div>
      <div v-if="loading" class="mt-4 text-gray-800 text-center">
        {{ $t('photoIsUploading') }}
      </div>
    </div>
    <div v-else-if="step === 4" class="max-w-lg mx-auto pt-32">
      <div class="mt-4 text-gray-800 text-center">
        {{ $t('photoWasUploaded') }}
      </div>
      <div class="flex items-center justify-center mt-4">
        <Button
          class="ml-2"
          primary
          :to="`/${$route.params.studio}`"
        >
          {{ $t('toHome') }}
        </Button>
      </div>
    </div>
    <Modal :value="loading">
      <div class="px-6 py-6 flex items-center justify-center">
        <LoadingCircle class="mr-4"/>
        <span class="text-gray-800">{{ $t('photoIsUploading') }}</span>
      </div>
    </Modal>
    <Modal :value="saveError">
      <div class="px-6 py-6 flex items-center justify-center">
        <div class="text-red-500 text-center">
          <Icon name="alert" font-classes="text-5xl"/>
        </div>
        <div class="w-full text-center">
          {{
            $t('photoExistingError', {
              id: this.$route.params.id,
            })
          }}
        </div>
        <Button class="mt-4 w-full" primary @click="closeErrorModal">
          {{ $t('close') }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Camera from '../../components/Camera.vue';
import ImageCrop from '../../components/ImageCrop.vue';
import LoadingCircle from '../../components/LoadingCircle.vue';

export default {
  components: {
    Camera,
    ImageCrop,
    LoadingCircle,
  },

  data() {
    return {
      step: 1,
      img: null,
      success: false,
      saveError: false,
    };
  },

  computed: {
    loading() {
      return this.$store.state.customer.photoLoading;
    },
  },

  methods: {
    onCapture(img) {
      this.img = img;
      this.step = 3;
    },
    openFileUpload() {
      document.getElementById('file')
        .click();
    },
    onFileUploadChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        this.createImage(files[0]);
      }
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.img = e.target.result;
        this.step = 3;
      };
      reader.readAsDataURL(file);
    },
    onSave() {
      const image = this.$refs.crop.generate();
      this.$store.dispatch('savePhoto', {
        customerId: this.$route.params.id,
        data: image,
      })
        .then(() => {
          this.step = 4;
          this.$router.push({ name: 'new-contract-success', query: { photo: '1' } });
        })
        .catch(() => {
          this.success = false;
          this.saveError = true;
        });
    },
    onCameraError() {
      this.step = 1;
      // eslint-disable-next-line no-alert
      alert('Kein Zugriff auf die Kamera möglich. Bitte prüfe die Berechtigungen.');
    },
    closeErrorModal() {
      this.saveError = false;
      this.$router.push(`/${this.$route.params.studio}/new-contract-success`);
    },
  },
};
</script>
