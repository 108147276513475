<template>
  <div class="flex flex-col justify-center items-center bg-black">
    <div class="left-0 ml-4 w-full fixed top-0 mt-16">
      <button
        class="text-white flex items-center text-lg hover:opacity-50 focus:outline-none"
        @click="$emit('back')"
      >
        <Icon name="arrow-left" />
        <span class="ml-1">{{ $t('back') }}</span>
      </button>
    </div>
    <WebCam
      ref="webcam"
      :height="720"
      :width="527"
      :autoplay="true"
      :selectFirstDevice="true"
      :resolution="resolution"
      screenshot-format="image/png"
      @started="onStarted"
      @stopped="onStopped"
      @error="onError"
      @cameras="onCameras"
      @camera-change="onCameraChange"
    />
    <div class="max-w-lg mx-6 flex w-full justify-between items-center mt-4">
      <div class="flex flex-1 w-full">
        <button
          v-if="devices && devices.length > 1"
          class="text-white focus:outline-none px-4 py-2"
          @click="changeCamera"
        >
          <Icon name="camera-switch" class="text-3xl" />
        </button>
      </div>
      <button
        class="flex-shrink-0 p-1 border border-white rounded-full focus:outline-none active:opacity-75"
        @click="onCapture"
      >
        <div class="bg-white h-12 w-12 rounded-full" />
      </button>
      <div class="flex flex-1 w-full" />
    </div>
  </div>
</template>

<script>
import { WebCam } from 'vue-web-cam';

export default {
  components: {
    WebCam,
  },

  data() {
    return {
      devices: null,
      deviceId: null,
      camera: null,
      img: null,
      selectedIndex: 0,
      resolution: {
        width: 700,
        height: 954,
      },
    };
  },

  methods: {
    onCapture() {
      this.img = this.$refs.webcam.capture();
      console.log(this.img);
      this.$emit('capture', this.img);
    },
    onStarted(stream) {
      console.log('On Started Event', stream);
    },
    onStopped(stream) {
      console.log('On Stopped Event', stream);
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      this.$emit('error', error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log('On Cameras Event', cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      this.devices.forEach((device, index) => {
        if (device.deviceId === deviceId) {
          this.selectedIndex = index;
        }
      });
      console.log('On Camera Change Event', deviceId);
    },
    changeCamera() {
      if (this.devices[this.selectedIndex + 1]) {
        this.$refs.webcam.changeCamera(this.devices[this.selectedIndex + 1].deviceId);
      } else {
        this.$refs.webcam.changeCamera(this.devices[0].deviceId);
      }
    },
  },
};
</script>
